import { useFormikContext } from 'formik';
import { FormEvent, SyntheticEvent } from 'react';
import { CheckboxProps, Form } from 'semantic-ui-react';

import { JiraProjectModalFieldMappingPicklist } from 'features/jira_project/modal/common/jira_project_modal_field_mapping_picklist';
import { JiraProjectModalTablePicklistCell } from 'features/jira_project/modal/common/jira_project_modal_table_picklist_cell';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { useFieldMappings } from 'features/jira_project/modal/sections/field_mapping_section/use_field_mappings';
import { shouldDisableFieldMapping } from 'features/jira_project/modal/sections/field_mapping_section/utils/picklist_utils';
import {
  CalculatedLpSystemId,
  FieldMappingRowType,
  FieldMappingValue,
  JiraCustomFieldType,
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
} from 'features/jira_project/modal/types';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';

interface JiraProjectModalCustomFieldColumnProps {
  picklistData: ReadonlyArray<FieldMappingValue>;
  nameMapping: FieldMappingValue;
  rowType: FieldMappingRowType;
}

export const JiraProjectModalCustomFieldColumn = ({
  picklistData,
  nameMapping,
  rowType,
}: JiraProjectModalCustomFieldColumnProps) => {
  const hasJiraCustomFieldMappingEnabled = useHasFeature(FeatureFlag.jiraCustomFieldMapping);
  const { canModify } = useJiraProjectModalContext();
  const { updateFieldMappingItem, findFieldMappingItem, createFieldMappingItem, deleteFieldMappingItem } =
    useFieldMappings();
  const { values } = useFormikContext<JiraProjectModalFormValues>();
  const fieldMappingItem = findFieldMappingItem({ rowId: nameMapping.id, rowType });
  const isDisabled = shouldDisableFieldMapping({
    canModify,
    hasJiraCustomFieldMappingEnabled,
    nameMapping,
    fieldMappingValues: values[JiraProjectModalFormFields.FieldMappings],
  });

  const handleFieldChange = (_: SyntheticEvent, { value: picklistChoiceId }: { value: string | undefined }) => {
    if (!picklistChoiceId) {
      fieldMappingItem && deleteFieldMappingItem({ fieldMappingItem });
    } else {
      fieldMappingItem
        ? updateFieldMappingItem({ row: nameMapping, rowType, fieldId: picklistChoiceId })
        : createFieldMappingItem({ row: nameMapping, rowType, fieldId: picklistChoiceId });
    }
  };

  const handleCheckboxChange = (_: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    updateFieldMappingItem({
      row: nameMapping,
      rowType,
      isTwoWaySyncChecked: data.checked,
      fieldId: getFieldChoiceId(),
    });
  };

  const getFieldChoiceId = () => {
    if (rowType === FieldMappingRowType.LpToJira) {
      return fieldMappingItem?.sourceFieldId ?? '';
    }
    return fieldMappingItem?.lpCustomFieldId?.toString() ?? fieldMappingItem?.lpSystemField ?? '';
  };

  const rowIsCalculatedSystemId = Object.values(CalculatedLpSystemId).includes(nameMapping.id as CalculatedLpSystemId);

  const rowIsSprint = nameMapping.custom === JiraCustomFieldType.Sprint;

  const shouldShowTwoWaySync =
    !rowIsSprint &&
    ((rowType === FieldMappingRowType.JiraToLp && fieldMappingItem) ||
      (hasJiraCustomFieldMappingEnabled && fieldMappingItem && !rowIsCalculatedSystemId));

  return (
    <JiraProjectModalTablePicklistCell
      picklist={
        <JiraProjectModalFieldMappingPicklist
          picklist={picklistData}
          onChange={handleFieldChange}
          disabled={isDisabled}
          rowType={rowType}
          nameMapping={nameMapping}
          isNameColumn={false}
          showNoneOption
          fieldChoiceId={getFieldChoiceId()}
        />
      }
      checkbox={
        shouldShowTwoWaySync && (
          <Form.Checkbox
            checked={fieldMappingItem.isPull && fieldMappingItem.isPush}
            onChange={handleCheckboxChange}
            disabled={isDisabled}
          />
        )
      }
      checkboxText={shouldShowTwoWaySync ? 'Enable Two-Way Sync' : ''}
    />
  );
};
