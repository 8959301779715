import { useFormikContext } from 'formik';
import { Dispatch, SyntheticEvent, SetStateAction } from 'react';
import { Dropdown, DropdownProps, Table } from 'semantic-ui-react';

import { StoryPointSchemeRowData } from 'features/administration/settings/settings_page/story_point_schemes/type';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import {
  getCopySchemeName,
  getSchemeFromId,
  getSchemeSelectionDropdownOptions,
} from 'features/jira_project/modal/sections/estimate_mapping_section/helpers';
import { JiraProjectModalFormFields, JiraProjectModalFormValues } from 'features/jira_project/modal/types';

export const SelectSchemeRow = ({
  schemes,
  setSelectedScheme,
}: {
  schemes: ReadonlyArray<StoryPointSchemeRowData>;
  setSelectedScheme: Dispatch<SetStateAction<StoryPointSchemeRowData | undefined>>;
}) => {
  const { setFieldValue } = useFormikContext<JiraProjectModalFormValues>();
  const options = getSchemeSelectionDropdownOptions(schemes);

  if (options.length === 0) {
    return null;
  }

  const handleSelectedSchemeChange = (_e: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const selectedOption = getSchemeFromId(schemes, data.value as number);
    const selectedSchemeValue = getCopySchemeName(selectedOption);
    setSelectedScheme(selectedOption);
    setFieldValue(JiraProjectModalFormFields.StoryPointSchemeName, selectedSchemeValue);
  };

  return (
    <JiraProjectModalTableRow>
      <JiraProjectModalTableNameCell content={<span>{'Select Scheme'}</span>} />
      <Table.Cell className="jira-project-modal__content-estimate-mapping-dropdown">
        <Dropdown
          closeOnChange
          onChange={handleSelectedSchemeChange}
          options={options}
          placeholder={'Select'}
          selection
        />
      </Table.Cell>
    </JiraProjectModalTableRow>
  );
};
