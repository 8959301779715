import { useFormikContext } from 'formik';
import React from 'react';
import { Input, InputProps, Table } from 'semantic-ui-react';

import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import { JiraProjectModalFormFields, JiraProjectModalFormValues } from 'features/jira_project/modal/types';

export const SchemeNameInput = () => {
  const { values, setFieldValue } = useFormikContext<JiraProjectModalFormValues>();

  const storyPointSchemeName = values[JiraProjectModalFormFields.StoryPointSchemeName];

  const handleChange = (_: React.SyntheticEvent, { value }: InputProps) => {
    setFieldValue(JiraProjectModalFormFields.StoryPointSchemeName, value);
  };

  return (
    <JiraProjectModalTableRow>
      <JiraProjectModalTableNameCell content={<span>{'Story Point Scheme Name'}</span>} />
      <Table.Cell className="jira-project-modal__content-estimate-mapping-story-point-scheme-name">
        <Input
          onChange={handleChange}
          value={storyPointSchemeName}
          name={JiraProjectModalFormFields.StoryPointSchemeName}
        />
      </Table.Cell>
    </JiraProjectModalTableRow>
  );
};
