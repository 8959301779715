import { ReactNode, useState } from 'react';
import { Popup } from 'semantic-ui-react';

import { TaskGroupHierarchy } from 'daos/external_integration_enums';
import { getDisabledPopupText } from 'features/jira_project/modal/sections/sync_settings_section/helpers';
import { SubFolderOrganizationValues } from 'features/jira_project/modal/types';

export const DisabledRadioButton = ({
  option,
  children,
}: {
  option: SubFolderOrganizationValues;
  children: ReactNode;
}) => {
  const [popUpConfig, setPopUpConfig] = useState({
    showFlatListPopUp: false,
    showEpicBasedPopUp: false,
  });
  const closePopup = () => setPopUpConfig({ showFlatListPopUp: false, showEpicBasedPopUp: false });
  const openFlatListPopUp = () => setPopUpConfig({ showFlatListPopUp: true, showEpicBasedPopUp: false });
  const openEpicBasedPopUp = () => setPopUpConfig({ showFlatListPopUp: false, showEpicBasedPopUp: true });

  const openPopup = (optionId: TaskGroupHierarchy) => {
    return () => {
      switch (optionId) {
        case TaskGroupHierarchy.GroupFlatLevel:
          openFlatListPopUp();
          break;
        case TaskGroupHierarchy.GroupByEpic:
          openEpicBasedPopUp();
          break;
        default:
          closePopup();
      }
    };
  };

  const getShouldShowPopUp = (optionId: TaskGroupHierarchy) => {
    switch (optionId) {
      case TaskGroupHierarchy.GroupFlatLevel:
        return popUpConfig.showFlatListPopUp;
      case TaskGroupHierarchy.GroupByEpic:
        return popUpConfig.showEpicBasedPopUp;
      default:
        return false;
    }
  };

  const popupContent = getDisabledPopupText(option.id);

  return (
    <span onMouseEnter={openPopup(option.id)} onMouseLeave={closePopup}>
      <Popup
        transition="false"
        trigger={children}
        content={popupContent}
        open={getShouldShowPopUp(option.id)}
        position={'top center'}
        size="small"
      />
    </span>
  );
};
