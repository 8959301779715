import { useFormikContext } from 'formik';
import { useState } from 'react';
import { Dropdown, DropdownItemProps, Table } from 'semantic-ui-react';

import { StoryPointSchemeActions } from 'daos/external_integration_enums';
import { StoryPointSchemeRowData } from 'features/administration/settings/settings_page/story_point_schemes/type';
import { JiraProjectModalInfo } from 'features/jira_project/modal/common/jira_project_modal_info';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import { JiraProjectTable } from 'features/jira_project/modal/common/jira_project_table';
import {
  getSchemeModifiedName,
  getSchemeValue,
  sortSchemesByDefault,
} from 'features/jira_project/modal/sections/estimate_mapping_section/helpers';
import { SchemeNameInput } from 'features/jira_project/modal/sections/estimate_mapping_section/scheme_name_input';
import { SelectSchemeRow } from 'features/jira_project/modal/sections/estimate_mapping_section/select_scheme_row';
import {
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  SchemeDisplayNames,
} from 'features/jira_project/modal/types';
import { useStoryPointSchemesWithDefault } from 'features/story_points/story_points_scheme_modal/use_story_point_schemes_with_default';

const popUpText =
  "When a Story Point is applied to a task that doesn’t have any assignments, low and high estimate fields are populated automatically to calculate the schedule. After the initial entry, Story Point and Remaining Estimate are independent of each other and changing one doesn't affect the other.";

export const StoryPointsSchemeChoice = () => {
  const [selectedScheme, setSelectedScheme] = useState<StoryPointSchemeRowData | undefined>();
  const [selectedSchemeActionDisplayName, setSelectedSchemeActionDisplayName] = useState<string>(
    SchemeDisplayNames.CreateNewScheme
  );
  const { storyPointSchemesRowData: flattenSchemes } = useStoryPointSchemesWithDefault();
  const { setFieldValue } = useFormikContext<JiraProjectModalFormValues>();

  const sortedByDefaultScheme = sortSchemesByDefault(flattenSchemes);

  const showSchemeNameInput = selectedSchemeActionDisplayName === SchemeDisplayNames.CreateNewScheme;
  const showSelectSchemeDropdown = selectedSchemeActionDisplayName === SchemeDisplayNames.CopyExistingScheme;

  const handleChangeSchemeActions = (_: React.SyntheticEvent<HTMLElement, Event>, data: DropdownItemProps) => {
    setSelectedScheme(undefined);
    setSelectedSchemeActionDisplayName(data.text as string);
    setFieldValue(JiraProjectModalFormFields.StoryPointSchemeActions, data.value as StoryPointSchemeActions);
    setFieldValue(JiraProjectModalFormFields.StoryPointSchemeName, '');
  };

  const handleSelectedSchemeChange = (_e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownItemProps) => {
    const selectedScheme = sortedByDefaultScheme.find((scheme) => getSchemeModifiedName(scheme) === data.text);
    if (selectedScheme) {
      setSelectedSchemeActionDisplayName(data.text as string);
      setFieldValue(JiraProjectModalFormFields.ExistingSchemeId, selectedScheme.id);
      setFieldValue(JiraProjectModalFormFields.StoryPointSchemeActions, data.value);
    }
  };

  return (
    <>
      <JiraProjectModalInfo
        title="Map story points to hours for project planning"
        popUpText={popUpText}
        helperText="Provide the hourly range for your story points to create task estimates and forecast schedule dates."
      />
      <JiraProjectTable>
        <JiraProjectModalTableRow>
          <JiraProjectModalTableNameCell content="Choose a Story Point Scheme" />
          <Table.Cell>
            <div className="jira-project-modal__content-estimate-mapping-dropdown">
              <Dropdown className="selection" text={selectedSchemeActionDisplayName}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    text={SchemeDisplayNames.CreateNewScheme}
                    value={StoryPointSchemeActions.CreateNew}
                    onClick={handleChangeSchemeActions}
                  />
                  <Dropdown.Item
                    text={SchemeDisplayNames.CopyExistingScheme}
                    value={StoryPointSchemeActions.CreateNew}
                    onClick={handleChangeSchemeActions}
                  />
                  <Dropdown.Divider />
                  {sortedByDefaultScheme.map((scheme) => (
                    <Dropdown.Item
                      key={scheme.id}
                      text={getSchemeModifiedName(scheme)}
                      value={getSchemeValue(scheme)}
                      onClick={handleSelectedSchemeChange}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Table.Cell>
        </JiraProjectModalTableRow>
        {showSchemeNameInput && <SchemeNameInput />}
        {showSelectSchemeDropdown && (
          <SelectSchemeRow schemes={sortedByDefaultScheme} setSelectedScheme={setSelectedScheme} />
        )}
        {selectedScheme && <SchemeNameInput />}
      </JiraProjectTable>
    </>
  );
};
