import { StoryPointSchemeActions } from 'daos/external_integration_enums';
import { StoryPointSchemesSortColumn } from 'features/administration/settings/settings_page/story_point_schemes/enums';
import { StoryPointSchemeRowData } from 'features/administration/settings/settings_page/story_point_schemes/type';

export const sortSchemesByDefault = (schemes: ReadonlyArray<StoryPointSchemeRowData>) => {
  if (!schemes || schemes.length === 0) {
    return [];
  }

  const defaultScheme = schemes.find((scheme) => scheme.default);
  const otherSchemes = schemes.filter((scheme) => !scheme.default);

  otherSchemes.sort((a, b) => a.schemeName.localeCompare(b.schemeName));

  return defaultScheme ? [defaultScheme, ...otherSchemes] : otherSchemes;
};

export const getSchemeModifiedName = (scheme: StoryPointSchemeRowData) => {
  return scheme.default ? `${scheme.schemeName} (default)` : scheme.schemeName;
};

export const getSchemeValue = (scheme: StoryPointSchemeRowData) => {
  return scheme.default ? StoryPointSchemeActions.UseDefault : StoryPointSchemeActions.UseExisting;
};

export const getSchemeSelectionDropdownOptions = (schemes: ReadonlyArray<StoryPointSchemeRowData>) => {
  return schemes.map((scheme) => ({
    key: scheme.id,
    text: getSchemeModifiedName(scheme),
    value: scheme.id,
  }));
};

export const getCopySchemeName = (selectedOption: StoryPointSchemeRowData | undefined) => {
  const schemeName = selectedOption?.[StoryPointSchemesSortColumn.SchemeName];
  return schemeName ? `${schemeName} (copy)` : '';
};

export const getSchemeFromId = (schemes: ReadonlyArray<StoryPointSchemeRowData>, id: number) => {
  return schemes.find((scheme) => scheme.id === id);
};
