import { noop } from 'lodash';
import { createContext, useContext } from 'react';

import { PackageStatus } from 'daos/enums';
import { JiraProjectModalContextProps } from 'features/jira_project/modal/context/types';
import { JiraTabKey } from 'features/jira_project/modal/types';

export const JiraProjectModalContext = createContext<JiraProjectModalContextProps>({
  activeTab: JiraTabKey.Welcome,
  completedTabs: {
    [JiraTabKey.Welcome]: true,
    [JiraTabKey.JiraInstance]: false,
    [JiraTabKey.ProjectAndIssuesFilter]: false,
    [JiraTabKey.SyncSettings]: false,
    [JiraTabKey.FieldMapping]: false,
    [JiraTabKey.RemainingEstimates]: false,
    [JiraTabKey.Launch]: false,
  },
  handleCloseButtonClick: noop,
  handleBackView: noop,
  packageId: 0,
  itemId: undefined,
  isEditMode: false,
  packageStatus: PackageStatus.SCHEDULED,
  tabs: [],
  submitHandler: () => noop,
  isSubmitting: false,
  isLoadingContent: false,
  setIsLoadingContent: () => noop,
  formError: undefined,
  setFormError: noop,
  setJiraProjectName: noop,
  previousTabKey: JiraTabKey.Welcome,
  showConfirmCloseModal: false,
  handlePanelClose: () => noop,
  setShowConfirmCloseModal: noop,
  jiraOAuthAccounts: [],
  setJiraOAuthAccounts: noop,
  jiraIntegrations: [],
  setJiraIntegrations: noop,
  jiraProjects: [],
  setJiraProjects: noop,
  jiraProjectDetails: undefined,
  setJiraProjectDetails: noop,
  jiraProjectMappingFieldData: [],
  setJiraProjectMappingFieldData: noop,
  connectionInformation: undefined,
  setConnectionInformation: noop,
  issueCount: undefined,
  setIssueCount: noop,
  userCount: undefined,
  setUserCount: noop,
  credentialsError: undefined,
  setCredentialsError: noop,
  canModify: true,
  canModifyCostCode: true,
  setCanModifyCostCode: noop,
  initialValuesForAnalyticsOnly: undefined,
  setInitialValuesForAnalyticsOnly: noop,
  addedLpFieldMappingRowItems: new Set(),
  setAddedLpFieldMappingRowItems: noop,
  addedJiraFieldMappingRowItems: new Set(),
  setAddedJiraFieldMappingRowItems: noop,
  isPremiumJiraUser: false,
  setIsLoadingJiraProjects: noop,
  isLoadingJiraProjects: false,
  canSelectLowerHierarchicalLevel: {
    isFlatListDisabled: false,
    isEpicBasedDisabled: false,
  },
  setCanSelectLowerHierarchicalLevel: noop,
});

export const useJiraProjectModalContext = () => useContext(JiraProjectModalContext);
