import { useFormikContext } from 'formik';
import { Dropdown, DropdownProps, Table } from 'semantic-ui-react';

import { EstimationType } from 'daos/external_integration_enums';
import { JiraProjectModalInfo } from 'features/jira_project/modal/common/jira_project_modal_info';
import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import { JiraProjectTable } from 'features/jira_project/modal/common/jira_project_table';
import { RemainingEstimates } from 'features/jira_project/modal/sections/estimate_mapping_section/remaining_estimates';
import { StoryPointsSchemeChoice } from 'features/jira_project/modal/sections/estimate_mapping_section/story_points_scheme_choice';
import { estimationTypeValues } from 'features/jira_project/modal/sections/estimate_mapping_section/utils';
import { JiraProjectModalFormFields, JiraProjectModalFormValues } from 'features/jira_project/modal/types';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';

const EstimateMappingSection = () => {
  const hasJiraStoryPoints = useHasFeature(FeatureFlag.jiraStoryPoints);
  const { values, setFieldValue } = useFormikContext<JiraProjectModalFormValues>();

  const estimationTypeValue = values.estimationType;

  const handleDropdownChange = (_: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
    setFieldValue(JiraProjectModalFormFields.EstimationType, value);
  };

  return (
    <div className="jira-project-modal__content-estimate-mapping" data-testid="jiraProjectModalEstimateMapping">
      {hasJiraStoryPoints && (
        <>
          <JiraProjectModalInfo title="Estimate Mapping*" />
          <JiraProjectTable>
            <JiraProjectModalTableRow>
              <JiraProjectModalTableNameCell content={<span>{'Estimation Type'}</span>} />
              <Table.Cell className="jira-project-modal__content-estimate-mapping-dropdown">
                <Dropdown
                  closeOnChange
                  onChange={handleDropdownChange}
                  options={estimationTypeValues}
                  selection
                  value={estimationTypeValue}
                />
              </Table.Cell>
            </JiraProjectModalTableRow>
          </JiraProjectTable>
        </>
      )}
      {estimationTypeValue === EstimationType.StoryPoints && <StoryPointsSchemeChoice />}
      {estimationTypeValue === EstimationType.RemainingTime && <RemainingEstimates />}
    </div>
  );
};

export default EstimateMappingSection;
