import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StoryPointScheme } from 'daos/model_types';
import { StoryPointSchemesDao } from 'daos/story_point_schemes';
import { StoryPointSchemesSortColumn } from 'features/administration/settings/settings_page/story_point_schemes/enums';
import {
  defaultStoryPointSchemesSort,
  useStoryPointsSchemesRowData,
} from 'features/administration/settings/settings_page/story_point_schemes/schemes_sort';
import { StoryPointSchemesSortProps } from 'features/administration/settings/settings_page/story_point_schemes/type';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { awaitRequestFinish } from 'lib/api';
import { getStoryPointSchemeForCurrentWorkspaceSortedByName } from 'redux/entities/selectors/story_point_schemes';

export const useStoryPointSchemesWithDefault = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const storyPointSchemes = useSelector(getStoryPointSchemeForCurrentWorkspaceSortedByName);
  const [storyPointSortOrder, setStoryPointSortOrder] =
    useState<StoryPointSchemesSortProps>(defaultStoryPointSchemesSort);

  const fetchStoryPointSchemes = useCallback(() => {
    const { uuid } = dispatch(
      StoryPointSchemesDao.fetchAll(
        { organizationId, workspaceId },
        { include: { includeStoryPoints: true, includeSchemeOwners: true } }
      )
    );
    dispatch(awaitRequestFinish<Array<StoryPointScheme>>(uuid, {}));
  }, [dispatch, organizationId, workspaceId]);

  useEffect(() => {
    fetchStoryPointSchemes();
  }, [fetchStoryPointSchemes]);

  const handleSetSortOrder = (sortColumn: StoryPointSchemesSortColumn) => () => {
    const isDifferentColumn = storyPointSortOrder.sortColumn !== sortColumn;

    const newSortDirection = isDifferentColumn ? false : !storyPointSortOrder.isAscending;

    setStoryPointSortOrder({ sortColumn, isAscending: newSortDirection });
  };

  const storyPointSchemesRowData = useStoryPointsSchemesRowData(storyPointSchemes);

  return {
    storyPointSchemesRowData,
    storyPointSortOrder,
    handleSetSortOrder,
  };
};
